exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-aml-policy-js": () => import("./../../../src/pages/aml-policy.js" /* webpackChunkName: "component---src-pages-aml-policy-js" */),
  "component---src-pages-ar-404-js": () => import("./../../../src/pages/ar/404.js" /* webpackChunkName: "component---src-pages-ar-404-js" */),
  "component---src-pages-ar-about-us-js": () => import("./../../../src/pages/ar/about-us.js" /* webpackChunkName: "component---src-pages-ar-about-us-js" */),
  "component---src-pages-ar-aml-policy-js": () => import("./../../../src/pages/ar/aml-policy.js" /* webpackChunkName: "component---src-pages-ar-aml-policy-js" */),
  "component---src-pages-ar-become-our-vip-js": () => import("./../../../src/pages/ar/become-our-vip.js" /* webpackChunkName: "component---src-pages-ar-become-our-vip-js" */),
  "component---src-pages-ar-contact-confirmation-js": () => import("./../../../src/pages/ar/contact-confirmation.js" /* webpackChunkName: "component---src-pages-ar-contact-confirmation-js" */),
  "component---src-pages-ar-contact-js": () => import("./../../../src/pages/ar/contact.js" /* webpackChunkName: "component---src-pages-ar-contact-js" */),
  "component---src-pages-ar-faq-js": () => import("./../../../src/pages/ar/faq.js" /* webpackChunkName: "component---src-pages-ar-faq-js" */),
  "component---src-pages-ar-how-does-it-work-js": () => import("./../../../src/pages/ar/how-does-it-work.js" /* webpackChunkName: "component---src-pages-ar-how-does-it-work-js" */),
  "component---src-pages-ar-index-js": () => import("./../../../src/pages/ar/index.js" /* webpackChunkName: "component---src-pages-ar-index-js" */),
  "component---src-pages-ar-privacy-policy-js": () => import("./../../../src/pages/ar/privacy-policy.js" /* webpackChunkName: "component---src-pages-ar-privacy-policy-js" */),
  "component---src-pages-ar-recommend-to-a-friend-js": () => import("./../../../src/pages/ar/recommend-to-a-friend.js" /* webpackChunkName: "component---src-pages-ar-recommend-to-a-friend-js" */),
  "component---src-pages-ar-terms-and-conditions-js": () => import("./../../../src/pages/ar/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-ar-terms-and-conditions-js" */),
  "component---src-pages-ar-terms-and-conditions-of-the-promotion-js": () => import("./../../../src/pages/ar/terms-and-conditions-of-the-promotion.js" /* webpackChunkName: "component---src-pages-ar-terms-and-conditions-of-the-promotion-js" */),
  "component---src-pages-ar-vip-confirmation-js": () => import("./../../../src/pages/ar/vip-confirmation.js" /* webpackChunkName: "component---src-pages-ar-vip-confirmation-js" */),
  "component---src-pages-become-our-vip-js": () => import("./../../../src/pages/become-our-vip.js" /* webpackChunkName: "component---src-pages-become-our-vip-js" */),
  "component---src-pages-contact-confirmation-js": () => import("./../../../src/pages/contact-confirmation.js" /* webpackChunkName: "component---src-pages-contact-confirmation-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cs-404-js": () => import("./../../../src/pages/cs/404.js" /* webpackChunkName: "component---src-pages-cs-404-js" */),
  "component---src-pages-cs-doporucit-priteli-js": () => import("./../../../src/pages/cs/doporucit-priteli.js" /* webpackChunkName: "component---src-pages-cs-doporucit-priteli-js" */),
  "component---src-pages-cs-faq-js": () => import("./../../../src/pages/cs/faq.js" /* webpackChunkName: "component---src-pages-cs-faq-js" */),
  "component---src-pages-cs-index-js": () => import("./../../../src/pages/cs/index.js" /* webpackChunkName: "component---src-pages-cs-index-js" */),
  "component---src-pages-cs-jak-to-funguje-js": () => import("./../../../src/pages/cs/jak-to-funguje.js" /* webpackChunkName: "component---src-pages-cs-jak-to-funguje-js" */),
  "component---src-pages-cs-kontakt-js": () => import("./../../../src/pages/cs/kontakt.js" /* webpackChunkName: "component---src-pages-cs-kontakt-js" */),
  "component---src-pages-cs-o-nas-js": () => import("./../../../src/pages/cs/o-nas.js" /* webpackChunkName: "component---src-pages-cs-o-nas-js" */),
  "component---src-pages-cs-podminky-a-pravidla-js": () => import("./../../../src/pages/cs/podminky-a-pravidla.js" /* webpackChunkName: "component---src-pages-cs-podminky-a-pravidla-js" */),
  "component---src-pages-cs-podminky-propagace-js": () => import("./../../../src/pages/cs/podminky-propagace.js" /* webpackChunkName: "component---src-pages-cs-podminky-propagace-js" */),
  "component---src-pages-cs-politika-aml-js": () => import("./../../../src/pages/cs/politika-aml.js" /* webpackChunkName: "component---src-pages-cs-politika-aml-js" */),
  "component---src-pages-cs-potvrzeni-kontaktu-js": () => import("./../../../src/pages/cs/potvrzeni-kontaktu.js" /* webpackChunkName: "component---src-pages-cs-potvrzeni-kontaktu-js" */),
  "component---src-pages-cs-stan-se-nasim-vip-js": () => import("./../../../src/pages/cs/stan-se-nasim-vip.js" /* webpackChunkName: "component---src-pages-cs-stan-se-nasim-vip-js" */),
  "component---src-pages-cs-vip-potvrzeni-js": () => import("./../../../src/pages/cs/vip-potvrzeni.js" /* webpackChunkName: "component---src-pages-cs-vip-potvrzeni-js" */),
  "component---src-pages-cs-zasady-ochrany-soukromi-js": () => import("./../../../src/pages/cs/zasady-ochrany-soukromi.js" /* webpackChunkName: "component---src-pages-cs-zasady-ochrany-soukromi-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-how-does-it-work-js": () => import("./../../../src/pages/how-does-it-work.js" /* webpackChunkName: "component---src-pages-how-does-it-work-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pl-404-js": () => import("./../../../src/pages/pl/404.js" /* webpackChunkName: "component---src-pages-pl-404-js" */),
  "component---src-pages-pl-faq-js": () => import("./../../../src/pages/pl/faq.js" /* webpackChunkName: "component---src-pages-pl-faq-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-jak-to-dziala-js": () => import("./../../../src/pages/pl/jak-to-dziala.js" /* webpackChunkName: "component---src-pages-pl-jak-to-dziala-js" */),
  "component---src-pages-pl-kontakt-js": () => import("./../../../src/pages/pl/kontakt.js" /* webpackChunkName: "component---src-pages-pl-kontakt-js" */),
  "component---src-pages-pl-o-nas-js": () => import("./../../../src/pages/pl/o-nas.js" /* webpackChunkName: "component---src-pages-pl-o-nas-js" */),
  "component---src-pages-pl-polecaj-znajomemu-js": () => import("./../../../src/pages/pl/polecaj-znajomemu.js" /* webpackChunkName: "component---src-pages-pl-polecaj-znajomemu-js" */),
  "component---src-pages-pl-polityka-aml-js": () => import("./../../../src/pages/pl/polityka-aml.js" /* webpackChunkName: "component---src-pages-pl-polityka-aml-js" */),
  "component---src-pages-pl-polityka-prywatnosci-js": () => import("./../../../src/pages/pl/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-pl-polityka-prywatnosci-js" */),
  "component---src-pages-pl-potwierdzenie-kontaktu-js": () => import("./../../../src/pages/pl/potwierdzenie-kontaktu.js" /* webpackChunkName: "component---src-pages-pl-potwierdzenie-kontaktu-js" */),
  "component---src-pages-pl-potwierdzenie-vip-js": () => import("./../../../src/pages/pl/potwierdzenie-vip.js" /* webpackChunkName: "component---src-pages-pl-potwierdzenie-vip-js" */),
  "component---src-pages-pl-regulamin-js": () => import("./../../../src/pages/pl/regulamin.js" /* webpackChunkName: "component---src-pages-pl-regulamin-js" */),
  "component---src-pages-pl-regulamin-promocji-js": () => import("./../../../src/pages/pl/regulamin-promocji.js" /* webpackChunkName: "component---src-pages-pl-regulamin-promocji-js" */),
  "component---src-pages-pl-zostan-naszym-vipem-js": () => import("./../../../src/pages/pl/zostan-naszym-vipem.js" /* webpackChunkName: "component---src-pages-pl-zostan-naszym-vipem-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-recommend-to-a-friend-js": () => import("./../../../src/pages/recommend-to-a-friend.js" /* webpackChunkName: "component---src-pages-recommend-to-a-friend-js" */),
  "component---src-pages-sk-404-js": () => import("./../../../src/pages/sk/404.js" /* webpackChunkName: "component---src-pages-sk-404-js" */),
  "component---src-pages-sk-ako-to-funguje-js": () => import("./../../../src/pages/sk/ako-to-funguje.js" /* webpackChunkName: "component---src-pages-sk-ako-to-funguje-js" */),
  "component---src-pages-sk-faq-js": () => import("./../../../src/pages/sk/faq.js" /* webpackChunkName: "component---src-pages-sk-faq-js" */),
  "component---src-pages-sk-index-js": () => import("./../../../src/pages/sk/index.js" /* webpackChunkName: "component---src-pages-sk-index-js" */),
  "component---src-pages-sk-kontakt-js": () => import("./../../../src/pages/sk/kontakt.js" /* webpackChunkName: "component---src-pages-sk-kontakt-js" */),
  "component---src-pages-sk-o-nas-js": () => import("./../../../src/pages/sk/o-nas.js" /* webpackChunkName: "component---src-pages-sk-o-nas-js" */),
  "component---src-pages-sk-odporucit-priatelovi-js": () => import("./../../../src/pages/sk/odporucit-priatelovi.js" /* webpackChunkName: "component---src-pages-sk-odporucit-priatelovi-js" */),
  "component---src-pages-sk-podmienky-js": () => import("./../../../src/pages/sk/podmienky.js" /* webpackChunkName: "component---src-pages-sk-podmienky-js" */),
  "component---src-pages-sk-politika-aml-js": () => import("./../../../src/pages/sk/politika-aml.js" /* webpackChunkName: "component---src-pages-sk-politika-aml-js" */),
  "component---src-pages-sk-potvrdenie-kontaktu-js": () => import("./../../../src/pages/sk/potvrdenie-kontaktu.js" /* webpackChunkName: "component---src-pages-sk-potvrdenie-kontaktu-js" */),
  "component---src-pages-sk-predpisy-propagacia-js": () => import("./../../../src/pages/sk/predpisy-propagacia.js" /* webpackChunkName: "component---src-pages-sk-predpisy-propagacia-js" */),
  "component---src-pages-sk-stante-sa-nasim-vip-js": () => import("./../../../src/pages/sk/stante-sa-nasim-vip.js" /* webpackChunkName: "component---src-pages-sk-stante-sa-nasim-vip-js" */),
  "component---src-pages-sk-vip-potvrdenie-js": () => import("./../../../src/pages/sk/vip-potvrdenie.js" /* webpackChunkName: "component---src-pages-sk-vip-potvrdenie-js" */),
  "component---src-pages-sk-zasady-ochrany-osobnych-udajov-js": () => import("./../../../src/pages/sk/zasady-ochrany-osobnych-udajov.js" /* webpackChunkName: "component---src-pages-sk-zasady-ochrany-osobnych-udajov-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-terms-and-conditions-of-the-promotion-js": () => import("./../../../src/pages/terms-and-conditions-of-the-promotion.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-of-the-promotion-js" */),
  "component---src-pages-vip-confirmation-js": () => import("./../../../src/pages/vip-confirmation.js" /* webpackChunkName: "component---src-pages-vip-confirmation-js" */)
}

